
/* Profile Picture Icon ------------------------------------ */
.profile-icon {
	width: 50px;
	height: 50px;
	background-color: #519fff;
	border-radius: 50%;
	object-fit: cover;
	text-align: center;
	vertical-align: middle;
}

.profile-icon img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin-right: 15px;
	object-fit: cover;
}

.profile-icon span{
	color: white;
	font-size: 20px;
    margin-top:15px;
}

/* Profile Picture Large Version  --------------------------*/
.profile-circle {
	width: 200px;
	height: 200px;
	background-color: #519fff;
	border-radius: 50%;
	object-fit: cover;
	text-align: center;
	vertical-align: middle;
}

.profile-circle img {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	margin-right: 15px;
	object-fit: cover;
}

.profile-circle span{
	color: white;
	font-size: 100px;
    margin-top:40px;
}

/* When you mouse over the large profile picture, fade in the overlay for editing*/
.profile-circle:hover .overlay {
	opacity: 50%;
  }

.overlay {
	position:absolute;
	top:0;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	opacity: 0;
	transition: .3s ease;
	background-color:#FFF;
  }

  .overlay i{
	color:black;
	font-size: 80px;
	margin-top:25%;
  }

  .overlay p{
	color:black;
	font-size: 20px;
  }